/* eslint-disable */

import ionicStorage from "@/js/ionicStorage";

function set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

function get(key: string) {
    return JSON.parse(<string>localStorage.getItem(key));
}

function remove(key: string) {
    localStorage.removeItem(key);
}

const STORAGE_KEY_TOKEN = "token";
const STORAGE_KEY_USERINFO = "userinfo";
const STORAGE_KEY_CURRENT_STUDENT = "currentStudent";
const STORAGE_KEY_CURRENT_STUDENT_ID = "currentStudentId";
const STORAGE_KEY_APPOINTMENT = "appointment";
const STORAGE_KEY_CLASS_TRIAL_ID = "CLASS_TRIAL_ID";
const STORAGE_KEY_TRIAL_COURSE_ID = "TRIAL_COURSE_ID";
const STORAGE_KEY_AFTER_TEST_FLAG = "STORAGE_KEY_AFTER_TEST_FLAG";//是否需要训后检测
const STORAGE_KEY_OEM = "STORAGE_KEY_OEM";
const STORAGE_IS_MOBILE = 'STORAGE_IS_MOBILE'; // 判断是否是移动端
const STORAGE_KEY_FORGET_START_TIME = 'STORAGE_KEY_FORGET_START_TIME'; // 抗遗忘开始时间

export default {

    //###########################
    //sessionStorage
    clearStudentInfoSession() {
        localStorage.removeItem(STORAGE_KEY_CURRENT_STUDENT);
        localStorage.removeItem(STORAGE_KEY_CURRENT_STUDENT_ID);
        localStorage.removeItem(STORAGE_KEY_APPOINTMENT);
        localStorage.removeItem(STORAGE_KEY_CLASS_TRIAL_ID);
        localStorage.removeItem(STORAGE_KEY_TRIAL_COURSE_ID);
        localStorage.removeItem(STORAGE_KEY_AFTER_TEST_FLAG);
        console.log("清理了用户临时数据。。。")
    },

    //清空Session
    async clearSession() {
        const storage = await ionicStorage
        const accountInfo = await storage.get('user_accountInfo')
        const isRememberPsd = await storage.get('is_remember_psd')
        const isReadAgreement = await storage.get('is_read_agreement')
        const oem = await storage.get('STORAGE_KEY_OEM')
        sessionStorage.clear();
        localStorage.clear();
        accountInfo && await storage.set('user_accountInfo', accountInfo)
        isRememberPsd && await storage.set('is_remember_psd', isRememberPsd)
        isReadAgreement && await storage.set('is_read_agreement', isReadAgreement)
        oem && await storage.set('STORAGE_KEY_OEM', oem)
        console.log("清理了全部用户数据。。。")

    },

    setToken(token: string) {
        localStorage.setItem(STORAGE_KEY_TOKEN, token);
    },

    getToken() {
        return localStorage.getItem(STORAGE_KEY_TOKEN);
    },

    //###############   OEM ################
    setOEMConfig(mapObj: object) {
        return localStorage.setItem(STORAGE_KEY_OEM, JSON.stringify(mapObj))
    },

    getOEMConfig() {
        return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_OEM));
    },

    getOEMUrl() {
        const oemConfig = this.getOEMConfig();
        if (oemConfig) {
            return oemConfig.OEM_RESOURCES_BASE + oemConfig.OEM_DETAIL_KEY_RESOURCES;
        }
        return null;
    },
    getOEMBASE() {
        const oemConfig = this.getOEMConfig();
        if (oemConfig) {
            return oemConfig.OEM_RESOURCES_BASE;
        }
        return null;
    },
    getOemBackgroundColor() {
        const oemConfig = this.getOEMConfig();
        if (oemConfig) {
            return oemConfig.OEM_DETAIL_KEY_BACKGROUND_COLOR;
        }
        return "#ffffff";
    },

    setUser(userinfo: object) {
        localStorage.setItem(STORAGE_KEY_USERINFO, JSON.stringify(userinfo));
    },

    getUser() {
        const user = JSON.parse(<string>localStorage.getItem(STORAGE_KEY_USERINFO));
        if (user) {
            return user;
        } else {
            alert("身份过期，请重新登录。")
            location.replace("/login");
        }
    },

    //获取当前用户
    getCurrentUser() {
        return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_USERINFO));
    },

    setStudent(student: object) {
        localStorage.setItem(STORAGE_KEY_CURRENT_STUDENT, JSON.stringify(student));
    },

    getStudent() {
        return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_CURRENT_STUDENT));
    },

    //保存正在上训练的用户编号
    setStudentId(studentId: any) {
        localStorage.setItem(STORAGE_KEY_CURRENT_STUDENT_ID, studentId);
    },

    //获取正在上训练的用户编号
    getStudentId() {
        return localStorage.getItem(STORAGE_KEY_CURRENT_STUDENT_ID);
    },

    //保存正在上训练的预约信息
    setAppointment(appointment: any) {
        localStorage.setItem(STORAGE_KEY_APPOINTMENT, JSON.stringify(appointment));
    },

    //获取正在上训练的预约信息
    getAppointment() {
        return JSON.parse(<string>localStorage.getItem(STORAGE_KEY_APPOINTMENT));
    },

    //保存当前体验记录编号，等待结束时更新结束时间
    setTrialId(trialId: string) {
        localStorage.setItem(STORAGE_KEY_CLASS_TRIAL_ID, trialId);
    },

    //获取当前正在体验的记录编号，准备更新结束时间
    getTrialId() {
        return localStorage.getItem(STORAGE_KEY_CLASS_TRIAL_ID);
    },

    //保存当前体验资料编号
    setTrialCourseId(courseId: string) {
        localStorage.setItem(STORAGE_KEY_TRIAL_COURSE_ID, courseId);
    },

    //获取当前正在体验的资料编号
    getTrialCourseId() {
        return localStorage.getItem(STORAGE_KEY_TRIAL_COURSE_ID);
    },

    //当前正在学单词，需要训后检测
    setAfterTestFlag(value: string) {
        localStorage.setItem(STORAGE_KEY_AFTER_TEST_FLAG, value);
    },

    //判断当前是否在学单词
    getAfterTestFlag() {
        return localStorage.getItem(STORAGE_KEY_AFTER_TEST_FLAG);
    },

    //清理当前学词状态
    clearAfterTestFlag() {
        localStorage.removeItem(STORAGE_KEY_AFTER_TEST_FLAG);
        console.log("清理当前学词状态。。。")
    },

    //记录抗遗忘开始时间
    setForgetStartTime() {
        let beginTime = new Date().getTime().toString();
        localStorage.setItem(STORAGE_KEY_FORGET_START_TIME, beginTime);
    },

    getForgetStartTime() {
        return localStorage.getItem(STORAGE_KEY_FORGET_START_TIME);
    },

    //检查是否具有权限
    checkFunctions(funcNo: string) {
        if (this.getUser() && this.getUser().menus) {
            for (let i = 0; i < this.getUser().menus.length; i++) {
                if (funcNo === this.getUser().menus[i]) {
                    return true;
                }
            }
        }
        return false;
    },

    isMobile() {
        const win: any = window
        if (win.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
            return true
        } else {
            return false
        }
    },
}

