<template>
  <ion-page>
      <ion-toolbar class="topbar">
        <ion-buttons slot="start">
          <ion-icon :icon="chevronBackOutline" @click="doBack" fill="clear" color="Medium"></ion-icon>
        </ion-buttons>
        <ion-title>用户注册</ion-title>
      </ion-toolbar>

    <ion-content
        :fullscreen="true"
        :style="{
          '--loginTopBg': `url(${brandImages.login_top}) center center /cover`,
          '--loginTopBgLixiao': `url(${brandImages.login_top_lixiao}) center center /cover`,
          '--background_color_login': `${BRAND_BACKGROUND_COLOR}`,
          '--personalbg': `url(${brandImages.personalbg}) top left /cover no-repeat`,
          '--welcome': `url(${brandImages.welcome}) top center no-repeat`,
        }"
    >
      <ion-grid style="padding: 0; height: 100%">

        <ion-row v-if="brand=='YIDIANLING'" class="loginTopbg loginTopbg_yidianling"></ion-row>
        <ion-row v-if="brand=='LIXIAO'" class="loginTopbg loginTopbg_lixiao"></ion-row>
        <div class="welcome"></div>
        <ion-list class="ion-padding main_list">
         
          <ion-item >
            <ion-label>
              <ion-icon slot="start" :icon="businessSharp"></ion-icon>
              机构
            </ion-label>
            <ion-input v-model="dataForm.schoolNo" type="text" placeholder="请输入机构编码" maxlength="20"
                       class="sc-ion-input-md-h sc-ion-input-md-s md"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-icon slot="start" :icon="school"></ion-icon>
              用户类型
            </ion-label>
            <ion-select v-model="dataForm.userType" interface="popover" placeholder="注册用户类型">
              <ion-select-option :value="1">用户</ion-select-option>
              <ion-select-option :value="2">陪练</ion-select-option>
              <ion-select-option :value="3" disabled>咨询师</ion-select-option>
            </ion-select>
          </ion-item>

          <!--姓名-->
          <ion-item class="item-interactive item-input item-has-placeholder item md in-list ion-focusable"
                    style="border-top:1px solid #ccc;">
            <ion-label>
              <ion-icon slot="start" :icon="personSharp"></ion-icon>
              姓名
            </ion-label>
            <ion-input v-model="dataForm.username" type="text" placeholder="请输入姓名" maxlength="20"
                       class="sc-ion-input-md-h sc-ion-input-md-s md">
            </ion-input>
          </ion-item>

          <!--身份证-->
          <ion-item v-if="dataForm.userType==2" class="item-interactive item-input item-has-placeholder item md in-list ion-focusable">
            <ion-label>
              <ion-icon slot="start" :icon="informationOutline"></ion-icon>
              身份证
            </ion-label>
            <ion-input v-model="dataForm.identity" type="text" placeholder="请输入身份证" maxlength="18"
                       class="sc-ion-input-md-h sc-ion-input-md-s md">
            </ion-input>
          </ion-item>

          <!--手机号-->
          <ion-item class="item-interactive item-input item-has-placeholder item md in-list ion-focusable">
            <ion-label>
              <ion-icon slot="start" :icon="phonePortraitSharp"></ion-icon>
              手机号
            </ion-label>
            <ion-input v-model="dataForm.phone" type="text" placeholder="请输入手机号" maxlength="11"
                       class="sc-ion-input-md-h sc-ion-input-md-s md">
            </ion-input>
          </ion-item>

          <!--密码-->
          <ion-item class="item-interactive item-input item-has-placeholder item md in-list ion-focusable">
            <ion-label>
              <ion-icon slot="start" :icon="lockClosed"></ion-icon>
              密码
            </ion-label>
            <ion-input v-model="dataForm.password" type="password" placeholder="请输入密码" maxlength="8"
                       ng-reflect-type="password" ng-reflect-placeholder="Password"
                       class="sc-ion-input-md-h sc-ion-input-md-s md">
            </ion-input>
          </ion-item>

          <!--验证码-->
          <ion-item class="item-interactive item-input item-has-placeholder item md in-list ion-focusable">
            <ion-label>
              <ion-icon slot="start" :icon="codeWorking"></ion-icon>
              验证码
            </ion-label>
            <ion-input v-model="dataForm.smsCode" type="text" placeholder="请输入验证码" maxlength="4"
                       class="sc-ion-input-md-h sc-ion-input-md-s md">
            </ion-input>
            <ion-button v-show="false" slot="end">获取手机验证码</ion-button>
            <ion-button class="mt-btn" :disabled="!sendButtonEnable"  slot="end" @click="getSMSCode">
              {{ RegCodeLabel }}
            </ion-button>
          </ion-item>

          <!--提交按钮-->
        
            <ion-item lines="none" button=""  @click="onRegister" class="btn_register">
            <ion-label>
              注册
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonItem,
  IonRow,
  IonInput,
  IonGrid,
  IonList,
  IonButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCol,
  IonIcon,
} from "@ionic/vue";

import {
  personSharp,
  phonePortraitSharp,
  codeWorking,
  businessSharp,
  school,
  chevronBackOutline,
  informationOutline,
  lockClosed,
} from "ionicons/icons";

import {BRAND_IMAGES} from "@/js/brandImages.ts";

export default {
  name: "Edu_Register",
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonRow,
    IonInput,
    IonGrid,
    IonList,
    IonButton,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonCol,
    IonIcon,
  },

  setup() {
    return {
      personSharp,
      phonePortraitSharp,
      codeWorking,
      businessSharp,
      school,
      chevronBackOutline,
      informationOutline,
      lockClosed,
    };
  },

  data() {
    return {

      errorMsg: undefined,

      //###############  短信验证 - 开始 ##############
      remainTime: 60,
      tickTimer: undefined,//短信发送倒计时定时器
      RegCodeLabel: "获取",
      sendButtonEnable: true,//发生验证码按钮是否可用
      //###############  短信验证 - 结束 ##############

      dataForm: {
        schoolNo: undefined,
        userType: undefined,
        username: undefined,
        identity: undefined,
        phone: undefined,
        password: undefined,
        smsCode: undefined,
        teacherId: undefined,
      },
      brandImages: BRAND_IMAGES,
    };
  },

  methods: {
    doBack() {
      history.go(-1);
    },

    onChangeUserType() {
      this.dataForm.identity = undefined;
    },

    getSMSCode() {
      const that = this;
      if (!this.checkPhone()) {
        this.alert(this.errorMsg);
        return;
      }

      this.ajax.post("/sendRegisterCode", this.dataForm).then((res) => {
        if (res.data.success) {
          this.remainTime = 60;
          this.sendButtonEnable = false;
          that.tickTimer = setInterval(function () {
            that.remainTime--;
            that.RegCodeLabel = '获取（' + that.remainTime + '）';
            console.log(new Date().toLocaleString())
          }, 1000);

          //设定60秒解除锁定，以防连续发送
          setTimeout(function () {
            clearInterval(that.tickTimer);
            that.RegCodeLabel = '获取';
            that.sendButtonEnable = true;
          }, 1000 * 60);
          this.alert("验证码已发送，请注意查收！");
        }
      });
    },

    onRegister() {
      this.dataForm.schoolNo=this.dataForm.schoolNo.trim();
      this.dataForm.phone=this.dataForm.phone.trim();
      this.dataForm.username=this.dataForm.username.trim();

      if (this.dataForm.userType == undefined) {
        this.alert("用户类型不能为空！");
        return false;
      }

      if (!this.checkUsername()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkPhone()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkPassword()) {
        this.alert(this.errorMsg);
        return;
      }

      if (!this.checkSMSCode()) {
        this.alert(this.errorMsg);
        return;
      }

      //机构不填，默认为总部
      if (this.dataForm.schoolNo == undefined) {
        this.dataForm.schoolNo = 9997;
      }


      this.dataForm.teacherId = this.userinfo ? this.userinfo.userId : null;
      this.ajax.post("/registerFromMobile", this.dataForm).then((res) => {
        if (res.data.success) {
          this.store.setToken(res.data.token);
          this.store.setUser(res.data.data);
          const uType = this.dataForm.userType;
          this.dataForm = {
            schoolNo: undefined,
            userType: undefined,
            username: undefined,
            phone: undefined,
            password: undefined,
          }
          this.alert("注册成功，您的账号为：" + res.data.data.account, function () {
            if (uType == 1) {
              location.replace("/StudentIndex");
            } else if (uType == 2) {
              location.replace("/TeacherIndex");
            }
          });
        }
      });
    },

    checkUsername() {
      const name = this.dataForm.username;
      if (name == undefined || name.trim() === "") {
        this.errorMsg = "姓名不能为空！";
        return false;
      }
      if (name.length > 20) {
        this.errorMsg = "姓名长度不能超过20个字符！";
        return false;
      }
      return true;
    },

    checkPhone() {
      const phone = this.dataForm.phone;
      if (phone == undefined || phone.trim() === "") {
        this.errorMsg = "手机号码不能为空！";
        return false;
      }

      if (phone.length > 11) {
        this.errorMsg = "手机号码不能超过11位！";
        return false;
      }

      if (!/^1\d{10}/.test(phone)) {
        this.errorMsg = "手机号码格式错误！";
        return false;
      }

      return true;
    },

    checkPassword() {
      const password = this.dataForm.password;
      if (password == undefined || password.trim() === "") {
        this.errorMsg = "密码不能为空！";
        return false;
      }

      if (password.length < 6 || password.length > 8) {
        this.errorMsg = "密码只能为6~8位任意！";
        return false;
      }
      return true;
    },

    //验证短信验证码
    checkSMSCode() {
      const smsCode = this.dataForm.smsCode;
      if (smsCode == undefined || smsCode.trim() === "") {
        this.errorMsg = "验证码不能为空！";
        return false;
      }

      if (!/^\d{4}$/.test(smsCode)) {
        this.errorMsg = "验证码只能为4位数字！";
        return false;
      }
      return true;
    }

  },

  mounted() {
    this.userinfo = this.store.getCurrentUser();
    // this.store.clearSession();
  },
};
</script>

<style scoped>
.topbar {
  --background: none;
  color:#000;
}

ion-content {
  --background: var(--personalbg);
}

.item {
  border-radius: 2vmin;
  margin-bottom: 2vmin;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  --inner-border-width: 0px;
  --border-width: 0;
  --highlight-color-focused: #99494a;
  overflow: hidden;
}

ion-button {
  font-size: inherit;
}

ion-item, ion-input, ion-select {
  font-size: 3vmin;
  height: auto;
  --border: none;
}

ion-list, ion-item, ion-col {
  background: none;
}

.buttonDisable {
  --background: #ccc;
}
.btn_register {
  --background: #ff9900;
  margin: 0 3vmin;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  margin-top:2vh;

  &:hover {
    background: #d40000;
    --color:#fff
  }

  &:active {
    background: #a00000;
  }
}
.mt-btn{
  --border: none;
  --box-shadow: none;
  --background: #7ea660;
}
.main_list{
  margin: 0 5vmin;
  background: linear-gradient(180deg, rgba(237,169,58,0.3) 0%, rgba(252,179,22,0.3) 10.46%, rgba(248,248,248,0.3) 100%);
  border-radius: 2vmin;
}
.welcome{
  background: var(--welcome);
  --background: #7ea660;
  background-size: 80%;
  width: 100%;
  height: 0;
  padding-bottom: 30%;
}
</style>
