<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab0" href="/StudentIndex">
          <ion-icon :icon="homeOutline"/>
          <ion-label>首页</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab1" href="/PersonalClassRecord">
          <ion-icon :icon="timerOutline"/>
          <ion-label>训练记录</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/StudentRanking">
          <ion-icon :icon="listOutline"/>
          <ion-label>我的排名</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/StudentPersonalIndex">
          <ion-icon :icon="personOutline"/>
          <ion-label>个人中心</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {timerOutline, libraryOutline, personOutline, listOutline, homeOutline} from "ionicons/icons";

export default {
  name: "StudentTabs",
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage,IonRouterOutlet},
  setup() {
    return {
      timerOutline,
      libraryOutline,
      personOutline,
      homeOutline,
      listOutline
    };
  },

  methods: {

    //

  },
  mounted() {
    //
  },


};
</script>
